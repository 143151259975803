import React, { Component } from 'react'

import Layout from '../components/layout'
import Footer from '../components/footer'
import Loading from '../components/loading-spinner';
import styles from './index.module.scss';

import SEO from '../components/seo'
import { navigateLater } from '../utils/navigate';

class IndexPage extends Component {
	
	render() { return (
		<Layout page="index">{auth => 
			<div className={styles.page}>
				<SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
				<section>
					<h1>Welcome to Danceable</h1>
					<Loading/>
					{auth && (navigateLater('/checkin'), <>
						<b>Loading checkin ...</b>
					</>)}
					{!auth && (navigateLater('/login'), <>
						<b>Not logged in.</b>
					</>)} 
				</section>
				<Footer/>
			</div>
		}</Layout>
	)}
}

export default IndexPage

